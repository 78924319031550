<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "ObjectRelations.NewObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-lookup-relation-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "ObjectRelations.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          @keyup="objectRelationNameKeyup()"
          id="Name"
          v-model="lookupRelationData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "ObjectRelations.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          @keyup="formulaNameKeyup()"
          v-model="lookupRelationData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="PageSize" class="form-label required">{{
          $t(
            "ObjectRelations.PageSize",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="number"
          min="0"
          class="form-control"
          id="PageSize"
          v-model="lookupRelationData.pageItemCount"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="LookupObject" class="form-label required">{{
          $t(
            "ObjectRelations.LookupObjectName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeLookupObject"
          :resetSelect="selectLookupValueReset"
          :allowEmpty="false"
          :isParameters="true"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          :routeLink="String.format('#/CustomObject/Detail/')"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryLookupObjectsWithFormula?customObjectFormulaName={0}',
              this.lookupRelationData.customObjectFormulaName
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="Viewfilter" class="form-label required">{{
          $t(
            "ObjectRelations.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeSystemViewFilter"
          :resetSelect="selectValueReset"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
              this.lookupRelationData.lookupObjectPublicId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          selectedPrimaryKey="publicId"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummarySystemViewFiltersWithFormula?coFormulaName={0}',
              this.lookupRelationData.lookupObjectFormulaName
            )
          "
          :isDisabled="
            String.isNullOrWhiteSpace(
              this.lookupRelationData.lookupObjectFormulaName
            )
              ? true
              : false
          "
        />
      </div>
      <div class="col col-md-6">
        <label for="FieldPublicId" class="form-label required">{{
          $t(
            "ObjectRelations.FieldName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeField"
          :resetSelect="selectValueReset"
          :allowEmpty="false"
          :isParameters="true"
          selectedPrimaryKey="publicId"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryLookupFieldsWithFormula?type=Lookup&parentFormulaName={0}&query={1}',
              this.lookupRelationData.customObjectFormulaName,
              this.lookupRelationData.lookupObjectFormulaName
            )
          "
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.lookupRelationData.lookupObjectPublicId
            )
          "
          :isDisabled="
            String.isNullOrWhiteSpace(
              this.lookupRelationData.lookupObjectFormulaName
            )
              ? true
              : false
          "
        />
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check">
        <label for="ShowInNewPage" class="form-label">{{
          $t(
            "ObjectRelations.ShowInNewPage",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="ShowInNewPage"
          v-model="lookupRelationData.isShowInNewPage"
        />
      </div>
      <div class="form-check">
        <label for="ShowInEditPage" class="form-label">{{
          $t(
            "ObjectRelations.ShowInEditPage",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="ShowInEditPage"
          v-model="lookupRelationData.isShowInEditPage"
        />
      </div>
      <div class="form-check">
        <label for="IsItLoadedOnFirstRequest" class="form-label">{{
          $t(
            "ObjectRelations.IsItLoadedOnFirstRequest",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsItLoadedOnFirstRequest"
          v-model="lookupRelationData.isLoadedOnFirstRequest"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="lookupRelationData.isActive"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewObjectRelation",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      lookupRelationData: {
        isActive: true,
        pageItemCount: 10,
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
      },
      isSuccess: false,
      selectValueReset: false,
      selectLookupValueReset: false,
      nullObject: [{ key: null, value: null }],
    };
  },
  methods: {
    onChangeLookupObject: function (selected) {
      this.lookupRelationData.lookupObjectFormulaName = selected.key;
      this.lookupRelationData.lookupObjectPublicId = selected.publicId;
      this.selectValueReset = !this.selectValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeSystemViewFilter(this.nullObject);
        this.onChangeField(this.nullObject);
      }
    },
    onChangeSystemViewFilter: function (selected) {
      this.lookupRelationData.viewFilterFormulaName = selected.key;
      this.lookupRelationData.viewFilterPublicId = selected.publicId;
    },
    onChangeField: function (selected) {
      this.lookupRelationData.fieldFormulaName = selected.key;
      this.lookupRelationData.fieldPublicId = selected.publicId;
    },
    objectRelationNameKeyup() {
      if (String.isNullOrWhiteSpace(this.lookupRelationData.name)) {
        this.lookupRelationData.formulaName = "";
        return;
      }
      this.lookupRelationData.formulaName = String.replaceAlphanumeric(
        this.lookupRelationData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.lookupRelationData.formulaName = String.replaceAlphanumeric(
        this.lookupRelationData.formulaName,
        "_"
      ).toUpperCase();
    },
    onClear() {
      this.lookupRelationData = {
        isActive: true,
        pageItemCount: 10,
        customObjectPublicId: this.lookupRelationData.customObjectPublicId,
        customObjectFormulaName:
          this.lookupRelationData.customObjectFormulaName,
      };
      this.selectLookupValueReset = !this.selectLookupValueReset;
      $(".form-lookup-relation-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-lookup-relation-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-LookupRelationNew", { ...this.lookupRelationData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/ObjectRelations/" +
                  this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.lookupRelationData.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.lookupRelationData.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
  mounted() {},
};
</script>
